import React from 'react';
import Container from '@mui/material/Container';
import { Typography, Paper, Grid } from '@mui/material';
import './levels.css';
import HeadingContainer from "../heading-container/HeadingContainer";

function LevelCard({ level, ageRange, description }) {
    return (
        <Grid item xs={12} sm={6} md={6}>
            <Paper className="levelCard" elevation={3}>
                <Typography variant="h6" className="levelTitle">
                    {level}
                    
                </Typography>
                <Typography variant="subtitle1" className="ageRange">
                    Age range: {ageRange}
                </Typography>
                <Typography variant="body1" className="description">
                    {description}
                </Typography>
            </Paper>
        </Grid>
    );
}

function Levels() {
    const levels = [
        {
            level: "Toddler Program",
            ageRange: "18 months - 2 years",
            description: "Our Toddler Program creates a warm, safe space where little ones begin their educational journey. Through play and guided interactions, toddlers develop early social skills, motor abilities, and explore the world around them in a nurturing environment."
        },
        {
            level: "Nursery Program",
            ageRange: "2 - 3 years",
            description: "In the Nursery Program, children continue building social skills, confidence, and early learning abilities. Through structured play, hands-on activities, and group experiences, they start developing basic communication skills, motor coordination, and emotional intelligence."
        }
        ,
        {
            level: "Pre-Kindergarten Program",
            ageRange: "3 - 4 years",
            description: "Our Pre-Kindergarten Program provides a foundation for early learning in literacy, numeracy, and creativity. Children engage in fun, interactive activities that promote their cognitive development while focusing on socialization and self-expression through play."
        }
        ,
        {
            level: "Kindergarten Program",
            ageRange: "4 - 5 years",
            description: "The Kindergarten Program emphasizes readiness for primary school by expanding on skills in reading, writing, math, and critical thinking. With a focus on hands-on learning, children also build their physical and social abilities through structured play and group work."
        },

        {
            level: "Primary School (I to V)",
            ageRange: "6 - 10 years",
            description: "Our Primary School program focuses on building a strong academic foundation in subjects such as math, science, language arts, and social studies. We encourage independent thinking, problem-solving, and collaboration, ensuring each child’s intellectual and personal growth."
        },

        {
            level: "Primary School (VI to VIII)",
            ageRange: "11 - 14 years",
            description: "In the higher primary years, students continue to expand their academic knowledge and are introduced to more complex subjects and critical thinking skills. This stage emphasizes increased responsibility, deeper learning in core subjects, and physical education to support overall well-being."
        }

    ];

    return (
        <div className="levelsContainer">
            <HeadingContainer title="Levels" />
            <Container maxWidth="md" className="contentContainer">
                
                <Grid container spacing={2} justifyContent="center">
                    {levels.map((level, index) => (
                        <LevelCard
                            key={index}
                            level={level.level}
                            ageRange={level.ageRange}
                            description={level.description}
                        />
                    ))}
                </Grid>
            </Container>
        </div>
    );
}

export default Levels;
