import React from "react";
// import Under from '../underCon/Under'
import "./management.css";
import Director from "../../assests/images/Picture1.png";
import Director1 from "../../assests/images/Picture2.png";
function Management() {
  return (
    <div className="management-container">
      <div className="m-part-1">
        <img
          src={Director}
          alt=""
          style={{ height: "200px", width: "200px" }}
        />
        <h5>SHRI. N. K. SHARMA</h5>
        <h5>Managing Director</h5>
        <h5>Retd. RPS</h5>
        <p>
          Dear Parents and Guardians, Welcome to White Wings School! We are
          dedicated to providing a well-rounded education for students from
          Playgroup to 8th grade. Our approach focuses on academic excellence,
          emotional growth, and character development, ensuring that each child
          becomes a confident and responsible individual. At White Wings, we
          believe in a student-centered learning environment, offering a mix of
          academics, social activities, and extracurriculars. Our skilled
          educators create a nurturing space where children can develop vital
          life skills like problem-solving, communication, and teamwork. We aim
          to balance rigorous learning with emotional and physical development,
          empowering students to succeed both inside and outside the classroom.
          We are committed to fostering a sense of curiosity, creativity, and
          self-confidence in every child, providing them with the tools they
          need for future success. Thank you for choosing White Wings School—we
          look forward to working with you to support your child’s growth and
          educational journey.
        </p>
      </div>
      <div className="m-part-2">
        <img
          src={Director1}
          alt=""
          style={{ height: "200px", width: "200px" }}
        />
         <h5>Mr. Rahul Sharma</h5>
        <h5>Director</h5>
        <h5>MTech(CSE), PHD*</h5>

        <p>
          Welcome to White Wings School, where we provide an outstanding educational experience from Playgroup to 8th grade. Our team of highly qualified educators is committed to offering a rigorous academic curriculum that challenges students to reach their full intellectual potential while encouraging critical thinking and creativity.
In addition to strong academics, we place great emphasis on physical education, understanding that a healthy body is key to a healthy mind. Our students participate in various physical activities that support their overall fitness and well-being.
At White Wings, we aim to foster well-rounded individuals who excel both academically and physically, preparing them for success in every aspect of life. We are excited to partner with you in this important journey and look forward to a year of growth, achievement, and development.
Thank you for considering White Wings Pre-Primary School as a partner in your child's educational journey. We look forward to the privilege of nurturing and inspiring your little one, and together, creating a strong foundation for their future success.

        </p>
      </div>
    </div>
  );
}

export default Management;
