import React from "react";
import Container from "@mui/material/Container";
import "./Fee.css";
// import Fee from "../../../assests/images/fee.jpeg"
import NewFee from "../../../assests/images/feePicture1.png";
import HeadingContainer from "../../heading-container/HeadingContainer";

function Admission() {
  return (
    <>
      <HeadingContainer title="Fee Structure" />
      <Container maxWidth="xl">
        <hr />
        {/* <div className='fee-div'>
                   <img src={Fee} alt="" />
               </div> */}
        <div>
          <div className="custom_card">
            <p>
              <b>Update date: 22 feb 2025</b>
            </p>
            <p>Registration process & Admission policy:</p>
            <div className="feeProcess">
              <img src={NewFee} alt="" />
            </div>
          </div>

          <div className="custom_card">
            <p>
              <b>
                Parents seeking admission for his / her ward to White Wings
                Jaipur must fulfil the following prerequisites :
              </b>
            </p>
            <div>
              <ul>
                <li>Admission is available from class Play Group to VII.</li>
                <li>
                  Parents have to complete the registration formalities at the
                  admission time.
                </li>
                <li>
                  Selected students along with parents would be called for
                  interaction on a specified date and time (both the parents
                  must be present)
                </li>
                <li>Age Criteria</li>
                <li>
                 Pre-knowledge assessment syllabus for admission 
                </li>
              </ul>
            </div>
          </div>

          <div className="custom_card">
            <p style={{ marginBottom: 0 }}>
              <b>
                At the time of registration, the following documents along with
                registration fee should be submitted at the Admission Section.
              </b>
            </p>
            <p style={{ marginTop: 0 }}>
              Filled in form should be submitted in the office along with
            </p>
            <ul>
              <li>
                Five Latest coloured passport size photograph of the student.
              </li>
              <li>Photograph of the parent with the child in [5cm x 3.5cm]</li>
              <li>Photocopy of Birth Certificate (self attested).</li>
              <li>Original Medical certificate of the student.</li>
              <li>
                Photocopy of the result of the previous class (self attested).
              </li>
              <li>
                Photocopy of student’s and parent’s Aadhaar card (self
                attested).
              </li>
              <li>Photocopy of student’s passport (if applicable).</li>
              <li>Photocopy of Local Address proof. (self attested)</li>
              <li>Photocopy of Minority certificate. ( if applicable )</li>
              <li>
                In case the registration form is filled by guardians other than
                parents, it should be clearly indicated
              </li>
            </ul>
          </div>

          <div className="custom_card">
            <p>
              <b>
                Note: Incomplete or illegible Registration Forms, without photos
                will not be accepted.
              </b>
            </p>
            <ul>
              <li>
                A basic admission test will be conducted on specified date for
                eligibility of that particular class. Only those candidates who
                clear the written test will be called for an interview, both
                parents are required to be present at the time of the interview
              </li>
              <li>
                The admission would be confirmed after the final interview.
              </li>
              <li>
                The school reserves the right to admit a student in the
                appropriate class after examining the student. Even after the
                admission is granted the student can either be adjusted in a
                lower or higher class, whichever the case may be, depending upon
                the student’s ability and at the discretion of the authorities.
              </li>
            </ul>
          </div>

          <div className="custom_card">
            <p>
              <b>
                Admission is strictly subject to fulfilling all conditions and
                submission of requisite documents.
              </b>
            </p>
            <p>
              <b>
                TC from the previous school must be submitted within 15 days
                after the commencement of the academic session.
              </b>
            </p>
            <p>
              <b>
                Outstation children must get their TC countersigned by DEO
                (District Educational Officer).
              </b>
            </p>
          </div>

          <div className="custom_card">
            <h3>Fees Structure</h3>
            <p>Rules & Regulations Regarding School Fee 2024-25</p>
            <table class="table">
              <thead>
                <tr>
                  <th>Index</th>
                  <th scope="col">Class</th>
                  <th scope="col">School Fee (Old Students)</th>
                </tr>
              </thead>
              <tbody class="table-group-divider">
                <tr>
                  <th scope="row">1</th>
                  <td>PG to UKG</td>
                  <td>26000</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>I to V</td>
                  <td>32000</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>VI to VII</td>
                  <td colspan="2">36000</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="custom_card">
            <ul>
              <li>1st Installment&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Current Date</li>
              <li>2nd Installment&nbsp;&nbsp;&nbsp;Date - 05th July, 2025</li>
              <li>
                3rd Installment&nbsp;&nbsp;&nbsp;&nbsp;Date - 05th October, 2025
              </li>
              <li>
                4th Installment&nbsp;&nbsp;&nbsp;&nbsp;Date - 05th January, 2025
              </li>
            </ul>
          </div>

          <div className="custom_card">
            <p style={{ fontWeight: "600", color: "red" }}>Note : </p>
            <ol>
              <li>
                For New Admission fee- Rs. 5,000 and Caution money (Refundable)-
                Rs. 1000
              </li>
              <li>
                Re-admission would be at the discreation of the Principal for
                which a re-admission fee will be applicable.
              </li>
              <li>
                The fee once paid, will not be refunded under any circumstances.
              </li>
              <li>
                The Management reserves the right to revise any policy/ fee at
                any time before and during the academic session.
              </li>
            </ol>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Admission;
