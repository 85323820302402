import React from "react";
import "./news.css";

const InfiniteScrollAnimation = () => {
  return (
    <div className="scroll-container">
      <div className="content">
        <div className="item">
          <ul>
            <li>Admission open for Session 2025-26</li>
            <li>Playgroup to 8th standard</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default InfiniteScrollAnimation;
