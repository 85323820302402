import React, { useState } from 'react'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import './Admission.css'
import axios from 'axios'
import toast from 'react-hot-toast';
import HeadingContainer from '../../heading-container/HeadingContainer'
import { LinearProgress,Alert } from '@mui/material'
import Snackbar from '@mui/material/Snackbar';


function Admission() {
    const [config, setConfig] = useState({
        studentName: "",
        parentName: "",
        dob: "2001-01-01",
        previousSchool: "",
        admissionForClass: "",
        parentOccupation: "",
        phone: "",
        email: ""
    });

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [typeMessage, setTypeMessage] = useState('success'); 
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const [visible, setVisible] = useState(false);
    const submitAdmission = async (e) => {
        e.preventDefault();
        if (config.studentName === "" || config.parentName === "" || config.dob === "" || config.previousSchool === "" || config.admissionForClass === "" || config.parentOccupation === "" || config.phone === "" || config.email === "") {
            toast.error('Please Fill All The Fields');
            setTypeMessage('error');
            setMessage('Please Fill All The Fields');
            setOpen(true);
            return;
        }

        setVisible(true);
        e.preventDefault();
        await axios.post('https://whitewings.onrender.com/admission/api/addAdmission', config, {
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_API_PASSWORD}`,
                "Content-Type": "application/json",
            }
        }).then((res) => {
            setVisible(false);
            setTypeMessage('success');
            setMessage('Admission Submitted Successfully');
            setOpen(true);
            toast.success('Admission Submitted Successfully');
            setConfig({
                studentName: "",
                parentName: "",
                dob: "2001-01-01",
                previousSchool: "",
                admissionForClass: "",
                parentOccupation: "",
                phone: "",
                email: ""
            });
        }).catch((err) => {
            setTypeMessage('error');
            setMessage('Admission Submission Failed');
            setVisible(false);
            setOpen(true);
            toast.error('Admission Submission Failed');
            console.log(err);
        }
        );
    }

    return (
        <>
            <HeadingContainer title="Admission" />

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={typeMessage} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>

            {visible && <LinearProgress color="success" />}
            <Container maxWidth="xl">

                <Typography variant="h5" style={{ color: "#00a859" }} className='text-center'>
                    Admission Open For Session 2025-2026
                </Typography>
                <hr />
                <form onSubmit={submitAdmission}>
                    <Grid container spacing={3} className='text-center' >
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Student's Name"
                                value={config.studentName}
                                onChange={(e) => setConfig({ ...config, studentName: e.target.value })}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Parent's Name"
                                value={config.parentName}
                                onChange={(e) => setConfig({ ...config, parentName: e.target.value })}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Date of Birth"
                                type='date'
                                value={config.dob}
                                onChange={(e) => setConfig({ ...config, dob: e.target.value })}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Previous School"
                                value={config.previousSchool}
                                onChange={(e) => setConfig({ ...config, previousSchool: e.target.value })}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Admission For Class"
                                value={config.admissionForClass}
                                onChange={(e) => setConfig({ ...config, admissionForClass: e.target.value })}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Occupation of Parent's"
                                value={config.parentOccupation}
                                onChange={(e) => setConfig({ ...config, parentOccupation: e.target.value })}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Mobile Number"
                                type='number'
                                value={config.phone}
                                onChange={(e) => setConfig({ ...config, phone: e.target.value })}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Email ID"
                                type='email'
                                value={config.email}
                                onChange={(e) => setConfig({ ...config, email: e.target.value })}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <button className='submitAdmission' type='submit'>
                                Submit
                            </button>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="body2" style={{ marginBottom: "2rem" }}>
                                *Registration of the child does not guarantee his/her Admission. Final decision will be of the Managing Committee*
                            </Typography>
                        </Grid>
                    </Grid>

                </form>
            </Container>
        </>
    )
}

export default Admission